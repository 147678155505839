import { EventEmitter, Injectable } from "@angular/core";
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpEvent, HttpRequest} from "@angular/common/http";
import { ModalLogoffService } from "./modal-logoff.service";
import { CredenciaisService } from "./credenciais.service";
import { ClientError } from "../models/client-error";
import { Observable, throwError } from "rxjs";
import { finalize, catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ShellService } from "./shell.service";
import * as strings from "../../assets/strings";
import { loggar } from "../constantes";
import { UploadFile } from "../models/integra/upload-file";

@Injectable()
export class HttpBaseService {
  public eventCarregar = new EventEmitter<boolean>();
  private apiUrl = "";
  private reportUrl = "";
  private body: Object = {};
  private headers: HttpHeaders;

  backendUrl() {
    throw new Error("Method not implemented.");
  }

  constructor(
    private http: HttpClient,
    private credenciaisService: CredenciaisService,
    private modalLogoffService: ModalLogoffService,
    private shellService: ShellService
  ) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
    this.apiUrl = environment.backendUrl;
    this.reportUrl = environment.reportsUrl;
  }

  getDefault(url: string, options: any, blockUI = true) {
    if (blockUI) {
      this.shellService.progressbar().show();
    }

    return this.http.get(url, options).pipe(
      finalize(() => {
        if (blockUI) {
          this.shellService.progressbar().hide();
        }
      }),
      catchError((res: HttpErrorResponse) => this.formatError(res))
    );
  }

  getLinkPDF(url: string, blockUI = true): Observable<any> {
    return this.http.get(this.reportUrl + url, { responseType: "text" }).pipe(
      map((data) => {
        //You can perform some transformation here
        return data;
      }),
      catchError((err) => {
        console.error(err);
        throw err;
      }
      ))
  }

  getPdf(url: string, blockUI = true): Observable<Blob> {
    if (blockUI) {
      this.shellService.progressbar().show();
    }

    // this.appendTokenToRequest();

    const options: {
      headers?: HttpHeaders;
      observe?: "body";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: "json";
      withCredentials?: boolean;
    } = {
      headers: this.headers,
      responseType: "blob" as "json"
    };

    return this.http.get<Blob>(this.reportUrl + url, options).pipe(
      finalize(() => {
        if (blockUI) {
          this.shellService.progressbar().hide();
        }
      }),
      catchError((res: HttpErrorResponse) => this.formatError(res))
    );
  }

  postPdf(url: string, body?: any, blockUI = true): Observable<Blob> {
    if (blockUI) {
      this.shellService.progressbar().show();
    }

    this.appendTokenToRequest();

    const options: {
      headers?: HttpHeaders;
      observe?: "body";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: "json";
      withCredentials?: boolean;
    } = {
      headers: this.headers,
      responseType: "blob" as "json"
    };

    return this.http.post<Blob>(this.reportUrl + url, JSON.stringify(body), options).pipe(
      finalize(() => {
        if (blockUI) {
          this.shellService.progressbar().hide();
        }
      }),
      catchError((res: HttpErrorResponse) => { return this.formatError(alert("Atenção: \n Não foi encontrado nenhum Relatório")); })
    );
  }

  postPdfUrl(url: string, body?: any, blockUI = true){
    if (blockUI) {
      this.shellService.progressbar().show();
    }

    this.appendTokenToRequest();

    if (body !== undefined) {
      this.body = JSON.stringify(body);
    }

    return this.http
      .post(this.reportUrl + url, this.body, this.getRequestOptions())
      .pipe(
      finalize(() => {
        if (blockUI) {
          this.shellService.progressbar().hide();
        }
      }),
      catchError((res: HttpErrorResponse) => { return this.formatError(alert("Atenção: \n Não foi encontrado nenhum Relatório")); })
    );
  }


 

  forgotPassword(url: string, blockUI = true, body?: any) {
    if (blockUI) {
      this.shellService.progressbar().show();
    }

    this.appendTokenToRequest();

    if (body !== undefined) {
      this.body = JSON.stringify(body);
    }
    return this.http
      .post(this.apiUrl + url, this.body, this.getRequestOptions())
      .pipe(
        finalize(() => {
          if (blockUI) {
            this.shellService.progressbar().hide();
          }
        }),
        map(res => this.refreshToken(res)),
        catchError((res: Response) => this.formatError(res))
      );
  }

  get(url: string, blockUI = true) {
    if (blockUI) {
      this.shellService.progressbar().show();
    }

    this.appendTokenToRequest();
    return this.http.get(this.apiUrl + url, this.getRequestOptions()).pipe(
      finalize(() => {
        if (blockUI) {
          this.shellService.progressbar().hide();
        }
      }),
      map(res => this.refreshToken(res)),
      catchError((res: Response) => this.formatError(res))
    );
  }

  postDefault(url: string, options: any, body?: any, blockUI = true) {
    if (blockUI) {
      this.shellService.progressbar().show();
    }
    return this.http.post(url, body, options).pipe(
      finalize(() => {
        if (blockUI) {
          this.shellService.progressbar().hide();
        }
      }),
      catchError((res: Response) => this.formatError(res))
    );
  }

  public postPDF(url: string, body: any): Observable<any> {
    this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.post<any>(this.reportUrl + url, JSON.stringify(body),
      {
        headers: this.headers,
        withCredentials: false
      }
    );
  }

  post(url: string, blockUI = true, body?: any) {
    if (blockUI) {
      this.shellService.progressbar().show();
    }

    this.appendTokenToRequest();

    if (body !== undefined) {
      this.body = JSON.stringify(body);
    }
    return this.http
      .post(this.apiUrl + url, this.body, this.getRequestOptions())
      .pipe(
        finalize(() => {
          if (blockUI) {
            this.shellService.progressbar().hide();
          }
        }),
        map(res => this.refreshToken(res)),
        catchError((res: Response) => this.formatError(res))
      );
  }

  put(url: string, blockUI = true, body?: any) {
    if (blockUI) {
      this.shellService.progressbar().show();
    }

    this.appendTokenToRequest();
    if (body !== undefined) {
      this.body = JSON.stringify(body);
    }
    return this.http
      .put(this.apiUrl + url, this.body, this.getRequestOptions())
      .pipe(
        finalize(() => {
          if (blockUI) {
            this.shellService.progressbar().hide();
          }
        }),
        map(res => this.refreshToken(res)),
        catchError((res: Response) => this.formatError(res))
      );
  }

  delete(url: string, blockUI = true, body?: any) {
    this.appendTokenToRequest();
    if (body !== undefined) {
      this.body = JSON.stringify(body);
    }
    return this.http.delete(this.apiUrl + url, this.getRequestOptions()).pipe(
      finalize(() => {
        if (blockUI) {
          this.shellService.progressbar().hide();
        }
      }),
      map(res => this.refreshToken(res)),
      catchError((res: Response) => this.formatError(res))
    );
  }

  formatError(res) {
    if (res.headers != null && res.headers.has("WWW-Authenticate")) {
      this.modalLogoffService.expirarSessao();
      return;
    }

    const erro: ClientError = ClientError.tratarErro(res);
    this.shellService
      .alert()
      .error({ timeout: 10000, messages: erro.userMessages });

    erro.devMessages
      ? console.warn(erro.devMessages)
      : console.warn(erro.userMessages);
    return throwError(res);
  }

  refreshToken(res) {
    // this.credenciaisService.refreshToken(res.headers.get(strings.tokenHeader));
    const contentType = res.headers.get("Content-type");
    if (!contentType) {
      return null;
    }
    if (contentType.includes("json")) {
      return res.body;
    } else if (contentType.includes("text")) {
      return res.text ? res.text() : res.body;
    }
  }

  appendTokenToRequest() {
    if (this.credenciaisService.Token) {
      this.headers = this.headers.set(
        strings.tokenHeader,
        strings.prefixTokenHeader + this.credenciaisService.Token
      );
    }
  }

  private getRequestOptions(): Object {
    return { headers: this.headers, observe: "response" };
  }

  private GerarCardsRegraNegocio(objMensagem: any) {
    let mensagem = "";

    objMensagem.forEach(element => {
      const titulo = element.identificador + " - " + element.titulo;

      if (!mensagem) {
        mensagem = this.MontarCard(titulo, element.mensagem);
      } else {
        mensagem = mensagem + this.MontarCard(titulo, element.mensagem);
      }
    });

    return mensagem;
  }

  private MontarCard(titulo: string, mensagem: string) {
    return `<mat-card>
              <h3>${titulo}</h3>
              <p>${mensagem}</p>
            </mat-card>`;
  }

  postList(url: string, blockUI = true, body?: any[]) {
    if (blockUI) {
      this.shellService.progressbar().show();
    }

    this.appendTokenToRequest();

    if (body !== undefined) {
      this.body = JSON.stringify(body);
    }
    return this.http
      .post(this.apiUrl + url, this.body, this.getRequestOptions())
      .pipe(
        finalize(() => {
          if (blockUI) {
            this.shellService.progressbar().hide();
          }
        }),
        map(res => this.refreshToken(res)),
        catchError((res: Response) => this.formatError(res))
      );
  }

  upload(objetoUpload: UploadFile, file: File): Observable<HttpEvent<any>> {
    //console.log("upload(objetoUpload: UploadFile---",objetoUpload)
    //console.log("upload(objetoUpload: file---",file)
    const formData: FormData = new FormData();
    formData.append('file', file);
    //formData.append('folderName', objetoUpload.folderName);
    //formData.append('folderNameId', objetoUpload.folderNameId);
    //formData.append('fileName', objetoUpload.fileName);
    //formData.append('tipo', objetoUpload.tipo);
    
    //upload PADRÃO
    if(objetoUpload.tipo == null && objetoUpload.subPasta == null && objetoUpload.idPai == null)
    {
      //console.log("upload--if(objetoUpload.tipo == null && objetoUpload.subPasta == null && objetoUpload.idPai == null)")
      const req = new HttpRequest('POST', `${this.apiUrl}/${objetoUpload.urlMetodo}?folderName=`+ objetoUpload.folderName 
                                                                                  + "&folderNameId=" + objetoUpload.folderNameId 
                                                                                  + "&fileName=" + objetoUpload.fileName, 
                                                                                  formData, 
                                                                                  {reportProgress: true,responseType: 'json'});
      this.eventCarregar.emit(true);
      return this.http.request(req);
    }

    //upload sem ter uma auto relação de pai
    if(objetoUpload.tipo != null && objetoUpload.subPasta == null && objetoUpload.idPai == null)
    {
      //console.log("upload--objetoUpload.tipo != null && objetoUpload.subPasta == null && objetoUpload.idPai == null")
      const req = new HttpRequest('POST', `${this.apiUrl}/${objetoUpload.urlMetodo}?folderName=`+ objetoUpload.folderName 
                                                                                  + "&folderNameId=" + objetoUpload.folderNameId 
                                                                                  + "&fileName=" + objetoUpload.fileName
                                                                                  + "&tipo=" + objetoUpload.tipo
                                                                                  , formData, {
                                                                                  reportProgress: true,
                                                                                  responseType: 'json'
                                });              

      //console.log("req",req)
      this.eventCarregar.emit(true);
      return this.http.request(req);
    }

    //upload se tiver subpasta
    if(objetoUpload.tipo != null && objetoUpload.subPasta != null && objetoUpload.idPai == null)
    {
      //console.log("upload--objetoUpload.tipo != null && objetoUpload.subPasta != null && objetoUpload.idPai == null")
      const req = new HttpRequest('POST', `${this.apiUrl}/${objetoUpload.urlMetodo}?folderName=`+ objetoUpload.folderName 
                                                                                  + "&folderNameId=" + objetoUpload.folderNameId 
                                                                                  + "&fileName=" + objetoUpload.fileName
                                                                                  + "&tipo=" + objetoUpload.tipo
                                                                                  + "&subPasta=" + objetoUpload.subPasta
                                                                                  , formData, {
                                                                                  reportProgress: true,
                                                                                  responseType: 'json'
                                });              

      //console.log("req",req)
      this.eventCarregar.emit(true);
      return this.http.request(req);
    }

    //upload sem subpasta e com auto relação de pai
    if(objetoUpload.tipo == null && objetoUpload.subPasta == null && objetoUpload.idPai != null)
    {
      //console.log("upload--objetoUpload.tipo == null && objetoUpload.subPasta == null && objetoUpload.idPai != null")
      const req = new HttpRequest('POST', `${this.apiUrl}/${objetoUpload.urlMetodo}?folderName=`+ objetoUpload.folderName 
                                                                                  + "&folderNameId=" + objetoUpload.folderNameId 
                                                                                  + "&fileName=" + objetoUpload.fileName
                                                                                  + "&idPai=" + objetoUpload.idPai
                                                                                  , formData, {
                                                                                  reportProgress: true,
                                                                                  responseType: 'json'
                                });
      this.eventCarregar.emit(true);
      return this.http.request(req);
    }

    //upload com tipo, subpast e auto relação de pai
    if(objetoUpload.tipo != null && objetoUpload.subPasta != null && objetoUpload.idPai != null)
      {
        //console.log("upload--objetoUpload.tipo != null && objetoUpload.subPasta != null && objetoUpload.idPai != null")
        const req = new HttpRequest('POST', `${this.apiUrl}/${objetoUpload.urlMetodo}?folderName=`+ objetoUpload.folderName 
                                                                                    + "&folderNameId=" + objetoUpload.folderNameId 
                                                                                    + "&fileName=" + objetoUpload.fileName
                                                                                    + "&tipo=" + objetoUpload.tipo
                                                                                    + "&idPai=" + objetoUpload.idPai
                                                                                    + "&subPasta=" + objetoUpload.subPasta
                                                                                    , formData, {
                                                                                    reportProgress: true,
                                                                                    responseType: 'json'
                                  });
        this.eventCarregar.emit(true);
        return this.http.request(req);
      }

      //upload com tipo, auto relação de pai
    if(objetoUpload.tipo != null && objetoUpload.subPasta == null && objetoUpload.idPai != null)
      {
        //console.log("upload--objetoUpload.tipo != null && objetoUpload.subPasta == null && objetoUpload.idPai != null")
        const req = new HttpRequest('POST', `${this.apiUrl}/${objetoUpload.urlMetodo}?folderName=`+ objetoUpload.folderName 
                                                                                    + "&folderNameId=" + objetoUpload.folderNameId 
                                                                                    + "&fileName=" + objetoUpload.fileName
                                                                                    + "&tipo=" + objetoUpload.tipo
                                                                                    + "&idPai=" + objetoUpload.idPai
                                                                                    , formData, {
                                                                                    reportProgress: true,
                                                                                    responseType: 'json'
                                  });
        this.eventCarregar.emit(true);
        return this.http.request(req);
      }
    
  }

  getUpload(): Observable<any> {
    return this.http.get(`${this.apiUrl}/files`);
  }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    const headers = new HttpHeaders({
      // Você pode adicionar headers se necessário
    });

    return this.http.post(`${this.apiUrl}/MiProponenteJovemAnexo/UploadFileTeste`, formData, { headers });
  }

}
