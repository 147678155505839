import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { validateAllFormFields } from '../../../constantes';
import { ShellService } from '../../../core/shell.service';
import { ChangePasswordModel } from '../../../models/integra/changepasswordmodel';
import { ChangePasswordService } from './change-password.service';
import { CredenciaisService } from '../../../core/credenciais.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public formGroup: FormGroup;
  public entity: ChangePasswordModel;
  public mensagem: string;
  public exibirLoading: boolean;
  public color: string = "green";
  public caracteres: string = "[@$!%*?&#]";

  constructor(
    private formBuilder: FormBuilder,
    private changePasswordService: ChangePasswordService,
    private shellService: ShellService,
    public thisDialogRef: MatDialogRef<ChangePasswordComponent>,
    private credenciaisService: CredenciaisService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    //console.log("ngOnInit---data", this.data)
    this.formGroup = this.formBuilder.group({
      Email: new FormControl(this.data.emailUsuario, Validators.required),
      Cpf: new FormControl(this.data.cpfUsuario),
      OldPassword: new FormControl(null, Validators.required),
      NewPassword: new FormControl(null, Validators.required),
      ConfirmPassword: new FormControl(null, Validators.required)
    });
  }

  private prepareToSave(formModel: ChangePasswordModel): void {
    this.entity = { ...this.entity, ...formModel };
  }


  public save(formModel: ChangePasswordModel, isValid: boolean): void {
    
    if(formModel.NewPassword !== formModel.ConfirmPassword){
      this.formGroup.controls['ConfirmPassword'].setErrors({'incorrect': true});
      isValid = false;
    }
    

    if (isValid) {
      this.color = "green"
      this.mensagem = 'Processando...';
      this.exibirLoading = true;

      this.prepareToSave(formModel);
      //console.log("save--ChangePasswordModel", this.entity)
      this.changePasswordService.changePassword(this.entity).subscribe(data => {
        this.mensagem = "Senha alterada com sucesso!"
        this.color = "green"
        this.thisDialogRef.close();  
        
        this.shellService.alert().success({ messages: ['Senha alterada com sucesso! Faça o Login novamente'], timeout: 5000 });
        setTimeout(() => this.logout(), 5000);
      },
      error => {
        //console.log('error', error)
        this.color = "red"
        this.exibirLoading = true;
        this.mensagem = 'Dados inválidos, verifique e tente novamente!';
      });

    } else {
      validateAllFormFields(this.formGroup);
      this.shellService.alert().error({ messages: ['Preencha todos os campos obrigatórios corretamente!'], timeout: 3000 });
    }
  }

  public handleError = (controlName: string, errorName: string) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  } 

  public logout()
  {
    this.credenciaisService.logout();

    if(JSON.parse(localStorage.getItem("currentUser")) != null)
    {localStorage.setObject("currentUser", null);}
  }

}
