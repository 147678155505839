import * as strings from '../../../../assets/strings';
import { LoginService } from './login.service';
import { NavegacaoService } from '../../../core/navegacao.service';
import { CredenciaisService } from '../../../core/credenciais.service';
import { Component, Inject, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../core/local-storage.service';
import { MatDialog } from "@angular/material/dialog";
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { first } from 'rxjs/operators';
import { HttpBaseService } from '../../../core/http-base.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  mensagem: string;
  exibirLoading: boolean;
  error: boolean;
  sigla: string;
  appIcon: string;
  theme: string;
  hide:boolean = true;
  public funcionarioLogado: string;
  public usuarioLogado:any;

  constructor(private loginService: LoginService,
    public credenciaisService: CredenciaisService,
    private localStorageService: LocalStorageService,
    private navegacaoService: NavegacaoService,
    private http: HttpBaseService,
    public dialog: MatDialog,
    private router: Router,
    ) 
    {
     this.theme = (this.localStorageService.getObject('theme-app') === 0 || this.localStorageService.getObject('theme-app') !== undefined
      ? 'agropolos-material-theme' : 'agropolos-material-dark-theme');
    }

  ngOnInit() {
    this.sigla = strings.sigla.toUpperCase();
    this.appIcon = strings.iconeApp;   
  }  

  solicitarToken(cpf: string, password: string) {
    this.mensagem = 'Autenticando...';
    this.exibirLoading = true;
    this.loginService.login(cpf, password).pipe(first()).subscribe(data => {    
    //console.log("solicitarToken", data)
      this.usuarioLogado = JSON.parse(localStorage.getItem("currentUser"));
      // this.http.get(`/Funcionario/BuscaFuncionarioLogado/${usuarioLogado.cpf}`, false).subscribe((data: any) => {
      //   this.funcionarioLogado = data.funcionario.pessoaFisica.dscNomeCompleto;
      // }); 
      //console.log("usuarioLogado",usuarioLogado)

      if(this.usuarioLogado.role == "ProjetoJovem")
      {
       // console.log("solicitarToken---ProjetoJovem", usuarioLogado)
        this.http.get('/miproponentejovem/localizaJovem?cpf='+ this.usuarioLogado.cpf, false).subscribe((info: any) => {
          this.router.navigate([`miproponentejovem/cadastro`, info.id]);
        });        
      }
      else if(this.usuarioLogado.role == "ProjetoMulher")
      {
        this.http.get('/mimulherrural/VerificaClassificadasAnalisadaHash/'+ this.usuarioLogado.hash, false).subscribe((list: any[]) => {
          var listaFinal = list;//.filter(res => res.classificacao > 0 && res.classificacao <=200 && (res.cpf.replace('.','').replace('-','') == this.usuarioLogado.cpf.replace('.','').replace('-','')) || (res.situacaoPosSelecao=="SELECIONADA RESERVA"));
          //console.log("solicitarToken---ProjetoMulher", listaFinal)

          if(listaFinal.length>0)
          {
            this.http.get('/mimulherrural/localizaMulherHash/' + this.usuarioLogado.hash, false).subscribe((info: any) => {
              //console.log("solicitarToken---ProjetoMulher---info", info)
              this.router.navigate([`mimulherrural/cadastro`, info.id]);
            }); 
          }
          else
          {
            this.mensagem = "Período de Seleção do Edital das Mulheres Rurais Encerrada.";
          }
          //console.log("solicitarToken---ProjetoMulher", usuarioLogado)
          //this.http.get('/mimulherrural/localizaMulher?cpf='+ usuarioLogado.cpf, false).subscribe((info: any) => {                 
        });
      }
      else if(this.usuarioLogado.role == "UsuarioAplicativo")
      {
        //console.log("solicitarToken---UsuarioAplicativo", usuarioLogado)
        this.mensagem = "Seu usuário deve ser utilizado no Aplicativo Integra.";
        //this.router.navigate(['/login']);
      }
      else
      {
        this.navegacaoService.redirecionar();
      }
    },
    error => {
      //console.log("error",error)
      this.exibirLoading = true;
      if(error.status== 401)
        this.mensagem = error.error.message;
      else 
        this.mensagem = error.error.message;
    });
  }

  resetarSenha() {
    this.dialog.open(ForgotPasswordComponent, {
      width: '600px',
    });
    // const _dialog = this.dialog.open(DominioCadastroComponent, {
    //   maxHeight: "80%",
    //   maxWidth: "90%",
    //   data: {
    //     id: 0,
    //     tipo: "TipoItemCusteio",
    //     tituloTipo: "Tipo Item Custeio"
    //   },
    //   disableClose: false
    // });
    // _dialog.afterClosed().subscribe(
    //   r => {
    //     this.listagemTipoCusteio();
    //   },
    //   erro => console.log(erro)
    // );
  }
}
